import React from 'react'
import { Fragment } from 'react'
import Navigation from './Navigation'
import Alert from './Alert'
// import Navigation from './NavigationInner'
import Footer from './Footer'
import '../../sass/global.scss'

export default function Layout({ children }) {
  return (
    <Fragment>
      <Navigation />
      <div className="content">
        { children }
      </div>
      <Footer />
      <Alert />
    </Fragment>
  )
}

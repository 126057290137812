import React, { Fragment } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import FaPhoneAlt from '../../assets/faPhoneAlt.svg';
import FaGlobeAmericas from '../../assets/faGlobeAmericas.svg';
import FaEnvelopeOpen from '../../assets/faEnvelopeOpen.svg';
import FaClock from '../../assets/faClock.svg';
import FaComment from '../../assets/faComment.svg';
import FaFacebookF from '../../assets/faFacebookF.svg';
import FaInstagram from '../../assets/faInstagram.svg';
import FaTwitter from '../../assets/faTwitter.svg';
import FaLinkedinIn from '../../assets/faLinkedinIn.svg';
// import FaYoutube from '../../assets/faYoutube.svg';
import Logo from '../../assets/PillarFinal--white__footer.svg';

export default function Navigation() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          email
          phone
          hours
          facebook
          twitter
          instagram
          linkedin
        }
      }
    }
  `)

  return (
    <Fragment>
      <div id="top"></div>
      <div id="about-sub" className="header--mobile__nav--sub">
        <div className="header--mobile__nav--sub--controls">
          <a href="#mobile-menu" aria-label="main menu">
            &lt;
          </a>
        </div>
        <ul className="nav--sublinks">
          <li>Attorneys</li>
          <li>
            <Link to="/about/darryl-j-dreyer/">Darryl J. Dreyer</Link>
          </li>
          <li>
            <Link to="/about/sarah-dreyer">Sarah Dreyer</Link>
          </li>
          <li>
            <Link to="/about/daniel-c-jackson-iii">Daniel C. Jackson, III</Link>
          </li>
          <li>
            <Link to="/about/tiffany-n-gagliano">Tiffany N. Gagliano</Link>
          </li>
          <li>
            <Link to="/about/steven-a-kimmel">Steven A. Kimmel</Link>
          </li>
        </ul>
      </div>
      <div id="mobile-menu" className="header--mobile__nav">
        <div className="header--mobile__nav--controls">
          <a href="#top" aria-label="close">
            X
          </a>
        </div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <a className="toSubMenu" href="#about-sub" aria-label="more"></a>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/practice-areas">Practice Areas</Link>
          </li>
          <li>
            <Link to="/results">Results</Link>
          </li>
          <li>
            <Link to="/testimonials">Testimonials</Link>
          </li>
        </ul>
        <div className="header--mobile__nav--social">
          <a href={`https://facebook.com/${data.site.siteMetadata.facebook}`} target="_blank" className="social-btn" rel="noopener noreferrer" aria-label="Facebook">
            <FaFacebookF />
          </a>
          <a href={`https://instagram.com/${data.site.siteMetadata.instagram}`} target="_blank" className="social-btn" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagram />
          </a>
          <a href={`https://twitter.com/${data.site.siteMetadata.twitter}`} target="_blank" className="social-btn" rel="noopener noreferrer" aria-label="Twitter/X">
            <FaTwitter />
          </a>
          <a href={`https://linkedin.com/company/${data.site.siteMetadata.linkedin}`} target="_blank" className="social-btn" rel="noopener noreferrer" aria-label="LinkedIn">
            <FaLinkedinIn />
          </a>
        </div>
        <a href="tel:845-787-7777" className="primary-btn mobile-nav">
          <FaPhoneAlt />
          &nbsp;&nbsp;Call Now
        </a>
        <Link className="secondary-btn mobile-nav" to="/es">
          <FaGlobeAmericas />&nbsp;
          Habla Espa&ntilde;ol
        </Link>
      </div>
      <header className="header">
        <div className="header--mobile">
          <div className="header--mobile__wrap">
            <div className="header--mobile__container">
              <div className="header--mobile__logo">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <div className="header--mobile__menu">
                <div className="header--mobile__menu--hamburger">
                  <a href="#mobile-menu" className="hamburger--button" aria-label="menu">
                    <span></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="topbar">
          <div className="topbar__background"></div>
          <div className="container">
            <div className="topbar__inner">
              <div className="topbar--left">
                <FaPhoneAlt />
                &nbsp;
                {data.site.siteMetadata.phone}
                <div className="bullet">&bull;</div>
                <FaEnvelopeOpen />
                &nbsp;
                {data.site.siteMetadata.email}
                <div className="bullet">&bull;</div>
                <FaClock />
                &nbsp;
                {data.site.siteMetadata.hours}
              </div>
              <div className="topbar--right">
                <Link className="primary-btn" to="/contact-us">
                  <FaComment />&nbsp;
                  Contact Us
                </Link>
                <Link className="secondary-btn" to="/es">
                  <FaGlobeAmericas />&nbsp;
                  Habla Espa&ntilde;ol
                </Link>
              </div>
              <div className="topbar--social">
                <a href={`https://facebook.com/${data.site.siteMetadata.facebook}`} target="_blank"className="social-btn" rel="noopener noreferrer" aria-label="Facebook">
                  <FaFacebookF />
                </a>
                <a href={`https://instagram.com/${data.site.siteMetadata.instagram}`} target="_blank" className="social-btn" rel="noopener noreferrer" aria-label="Instagram">
                  <FaInstagram />
                </a>
                <a href={`https://twitter.com/${data.site.siteMetadata.twitter}`} target="_blank" className="social-btn" rel="noopener noreferrer" aria-label="Twitter/X">
                  <FaTwitter />
                </a>
                <a href={`https://linkedin.com/company/${data.site.siteMetadata.linkedin}`} target="_blank" className="social-btn" rel="noopener noreferrer" aria-label="LinkedIn">
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
          </div>
        </div>
        <nav className="nav">
          <div className="nav__background"></div>
          <div className="container">
            <div className="nav__inner">
              <div className="nav__logo">
                <div className="nav__logo--frame">
                </div>
              </div>
              <div className="nav__right">
                <div className="mainmenu">
                  {/* embed in mainmenu--left if inserting search */}
                  <ul className="nav--links">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                      <ul className="nav--sublinks">
                        <li>
                          <Link to="/about/darryl-j-dreyer/">Darryl J. Dreyer</Link>
                        </li>
                        <li>
                          <Link to="/about/sarah-dreyer/">Sarah Dreyer</Link>
                        </li>
                        <li>
                          <Link to="/about/daniel-c-jackson-iii">Daniel C. Jackson, III</Link>
                        </li>
                        <li>
                          <Link to="/about/tiffany-n-gagliano">Tiffany N. Gagliano</Link>
                        </li>
                        <li>
                          <Link to="/about/steven-a-kimmel">Steven A. Kimmel</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/practice-areas">Practice Areas</Link>
                    </li>
                    <li>
                      <Link to="/results">Results</Link>
                    </li>
                    <li>
                      <Link to="/testimonials">Testimonials</Link>
                    </li>
                  </ul>
                  {/* mainmenu--right for search component */}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </Fragment>
  )
}